import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { FaCamera } from 'react-icons/fa';
import { ColumnsType } from 'antd/lib/table';
import { Table, Select, Input, Modal, Button, Image } from 'antd';

import { FaFile, FaPeopleCarry } from 'react-icons/fa';

import { useReactToPrint } from 'react-to-print';

import Card from 'components/molecules/Card';
import ButtonCustom from 'components/atoms/Buttons';

import SelectEtat from 'components/molecules/Inputs/Select';
import Notification from 'components/atoms/Notifications';

import API from 'constants/API';
import Routes from 'constants/Routes';

import Icon from 'components/atoms/Icon';
import { GET, PATCH, POST } from 'utils/Fetch';
import { FormatSelectUser } from 'models/UsersBuilder/UserBuilder';
import { NotificationContext } from 'contexts/Notifications';
import useOptions from 'utils/Hook/GetOptions';
import { ModelLivraisonHistory, ModelDocumentsListeLivraison, ModelLivraison } from 'models/ModelTypeData';
import { modelLivraison } from 'models/DefaultTypeData';
import SpanLabel from 'components/molecules/SpanLadel';
import { ExcelHeaderDefinitions } from 'utils/ExportXLS';
import ExportSelect from 'components/organisms/ExportSelect';

const definitionsTableau: ExcelHeaderDefinitions = [
    { header: 'Tiers source', data: 'raisonSocialeTiersSource', type: 'string' },
    { header: 'Tiers destinataire', data: 'raisonSocialeTiersDestination', type: 'string' },
    { header: 'Identifiant', data: 'Identifiant', type: 'string' },
    { header: 'Horaire', data: 'openingHours', type: 'string' },
    { header: 'Clé', data: 'numeroKey', type: 'string' },
    { header: "Heure d'arrrivée", data: 'arrivalTime', type: 'date' },
    { header: 'Adresse', data: 'Adresse1', type: 'string' },
    { header: 'Ville', data: 'concatTiersDestinationVille', type: 'string' },
    { header: 'Document', data: 'numero', type: 'string' },
    { header: 'Etat', data: 'Libelle_fk_etat', type: 'string' },
    { header: 'Nombre de colis', data: 'countColis', type: 'string' },
];
interface Options {
    label: string;
    value: string;
}

const Delivery = () => {
    const { id } = useParams<{ id: string }>();
    const { state, dispatch } = useContext(NotificationContext);
    const [delivery, setDelivery] = useState<ModelLivraison>(modelLivraison);
    const [copieData, setCopieData] = useState<ModelLivraison>(modelLivraison);
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [status, setStatus] = useState<Options>({ label: '', value: '' });
    const { statusOptions: statusOption } = useOptions({ getStatus: 'livraison' });
    const [userList, setUserList] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const [photo, setPhoto] = useState([]);
    const [open, setOpen] = useState<boolean>(false);
    const componentRef = useRef(null);

    const GetDelivery = async () => {
        const { status, message, ...response } = await GET(API.DELIVERY + id);
        if (!status) return dispatch({ message, status });
        // setDelivery(FormatDelivery(response));
        setDelivery(response.data);
        setCopieData(response.data);
    };

    const GetUserList = async () => {
        const { status, message, ...response } = await GET(API.USER_LIST);
        if (!status) return dispatch({ message, status });
        setUserList(FormatSelectUser(response));
    };

    const GetPhoto = async (idTier: number) => {
        const { status, message, ...response } = await GET(`${API.DELIVERY_PHOTO}?idTournee=${delivery.idTournee}&idLivraison=${id}&idTier=${idTier}`);
        if (!status) return dispatch({ message, status });
        setPhoto(response.data.photo);
        setOpen(true);
    };
    useEffect(() => {
        GetDelivery();
        GetUserList();
    }, []);
    console.log(photo);
    const ChangeDeliver = async (fk_livreur: number) => {
        const body = {
            fk_livreur: fk_livreur,
            id: +delivery.id,
        };

        const { status, message } = await PATCH(API.DELIVERY_CHANGE_DELIVER, body);
        dispatch({ status, message });
        GetDelivery();
    };

    const onSubmit = async (data: Options) => {
        const body = {
            fk_etat: data.value,
            fk_livraison: id,
        };
        const { status, message } = await POST(API.DELIVERY_CHANGE_ETAT, body);
        if (!status) return dispatch({ status, message });
        dispatch({ message, status });
        GetDelivery();
        setShowSelect(false);
    };

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `Livraison ${delivery.id}`,
    });

    function arraySearch(value: any) {
        const filterTable = copieData.list_documents.filter((o: any) =>
            Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())),
        );
        setDelivery((prevState) => ({
            ...prevState,
            list_documents: filterTable,
        }));
    }

    const columns: ColumnsType<ModelDocumentsListeLivraison> = [
        {
            title: 'Tiers source',
            sorter: (a, b) => a.raisonSocialeTiersSource.localeCompare(b.raisonSocialeTiersSource),
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.COMPANY}/${props.fk_tiersSource}`}>
                    {props.raisonSocialeTiersSource}
                </Link>
            ),
        },

        {
            title: 'Tiers destination',
            sorter: (a, b) => a.raisonSocialeTiersDestination.localeCompare(b.raisonSocialeTiersDestination),
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.COMPANY}/${props.fk_tiersDestination}`}>
                    {props.raisonSocialeTiersDestination}
                </Link>
            ),
        },
        {
            title: 'Identifiant',
            sorter: (a, b) => a.Identifiant.localeCompare(b.Identifiant),
            render: (text, props) => <span>{props.Identifiant}</span>,
        },
        {
            title: 'Horaires',
            render: (text, props) => (
                <div>
                    {props.openingHours &&
                        props.openingHours.split(';').map((row: any, i: number) => (
                            <span key={i}>
                                {row}
                                <br />
                            </span>
                        ))}
                </div>
            ),
        },
        {
            title: 'Clé',
            render: (text, props) => <SpanLabel couleur={props.couleurEtatKey} Libelle={props.numeroKey} />,
        },
        {
            title: "Heure d'arrivée",
            sorter: (a, b) => a.arrivalTime.localeCompare(b.arrivalTime),
            render: (text, props) => props.arrivalTime && dayjs(props.arrivalTime).format('DD/MM/YYYY HH:mm:ss'),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Adresse',
            render: (text, props) => (
                <span>
                    {props.Adresse1}
                    <br />
                    {props.concatTiersDestinationVille}
                </span>
            ),
        },
        {
            title: 'Document',
            sorter: (a, b) => (a.numero && b.numero ? a.numero.localeCompare(b.numero) : 0),
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.DOCUMENTS}/${props.id}`}>
                    {props.numero}
                </Link>
            ),
        },
        {
            title: 'État',
            sorter: (a, b) => a.Libelle_fk_etat.localeCompare(b.Libelle_fk_etat),
            render: (text, props) => <SpanLabel couleur={props.couleur} Libelle={props.Libelle_fk_etat} />,
        },
        {
            title: 'Colis',
            render: (text, props) => <span>{props.countColis}</span>,
        },
        {
            title: '',
            render: (text, props) => <Icon onClick={() => GetPhoto(props.fk_tiersDestination)} Icon={FaCamera} className="w-5" />,
        },
    ];
    const columnsEtatLivraison: ColumnsType<ModelLivraisonHistory> = [
        {
            title: 'Date',
            render: (text, props) => dayjs(props.date).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'Etat',
            render: (text, props) => <SpanLabel couleur={props.couleur} Libelle={props.Libelle_fk_etat} />,
        },
        {
            title: 'Livreur',
            render: (text, props) => <span>{props.concatNomPrenom}</span>,
        },
    ];
    function tailleModal() {
        if (photo.length === 1) return '30%';
        if (photo.length === 2) return '50%';
        return '70%';
    }
    return (
        <section className="mx-14 my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <Modal
                width={tailleModal()}
                open={open}
                footer={[
                    <Button type="primary" className=" !border-blue-700 !bg-blue-700" key="back" onClick={() => setOpen(false)}>
                        retour
                    </Button>,
                ]}
            >
                <div className="flex justify-around">
                    {photo.map((row, index) => (
                        <Image style={{ marginRight: '5px !important' }} key={index} width={300} src={row} />
                    ))}
                </div>
            </Modal>
            <div className="col-span-4 lg:col-span-2 row-start-2">
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={`Ajouté le ${delivery.createdAt}`}>
                    Livraison {delivery.id}
                </Card>
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info="Tournée">
                    {delivery.LibelleTournee}
                </Card>
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaPeopleCarry} info="Livreur">
                    <Select value={delivery.fk_livreur} placeholder="deliverer" options={userList} onChange={(value) => ChangeDeliver(value)} />
                </Card>
            </div>
            <Card className="col-span-4 row-start-2 md:row-start-2 lg:col-start-3 lg:col-span-5 flex flex-col">
                <small>Historique</small>
                <Table columns={columnsEtatLivraison} dataSource={delivery.history_livraison} pagination={false} />
            </Card>
            <div className="col-span-7 row-start-3 md:row-start-3 flex justify-between">
                <div className="flex items-center">
                    <ButtonCustom onClick={handlePrint} variant="outline">
                        imprimer
                    </ButtonCustom>
                    <ExportSelect
                        className="!font-bold ml-5"
                        data={delivery.list_documents}
                        nomComplet="documents_Liste.xlsx"
                        nomFichier="documents_Liste"
                        definitions={definitionsTableau}
                    />
                </div>

                <Input.Search style={{ width: '40%', marginLeft: '5%' }} allowClear placeholder="Recherche" onChange={arraySearch} />
            </div>

            <div className="col-span-6 row-start-4 md:row-start-4">
                <Table
                    columns={columns}
                    dataSource={delivery.list_documents}
                    pagination={
                        delivery.list_documents.length < 20
                            ? false
                            : {
                                  showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                                  position: ['topRight', 'bottomRight'],
                                  total: delivery.list_documents.length,
                                  defaultCurrent: 1,
                                  defaultPageSize: 20,
                              }
                    }
                />
            </div>
            <div className=" lg:col-start-5 row-start-1 lg:col-span-2 ">
                <Notification message={state.message} status={state.status} />
                {showSelect && (
                    <>
                        <SelectEtat name="etat" option={statusOption} onChange={(e: Options) => setStatus(e)} className="py-2" isMulti={false} />
                        <ButtonCustom
                            type="submit"
                            className="w-full flex items-center justify-center"
                            variant="secondary"
                            onClick={() => onSubmit(status)}
                            bold
                        >
                            Appliquer le changement
                        </ButtonCustom>
                    </>
                )}
                <ButtonCustom type="button" className="w-full flex items-center justify-center mt-5" onClick={() => setShowSelect(!showSelect)} bold>
                    {showSelect ? <p>Ne pas changer l&apos;état</p> : <p>Changer l&apos;état</p>}
                </ButtonCustom>
            </div>
        </section>
    );
};

export default Delivery;
