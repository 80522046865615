import React, { useContext, useEffect, useState } from 'react';

import { FaRegEdit, FaTrash } from 'react-icons/fa';

import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { NotificationContext } from 'contexts/Notifications';
import { Link } from 'react-router-dom';
import { DELETE, GET } from 'utils/Fetch';
import SchedulForm from './scheduleForm';
import Button from 'components/atoms/Buttons';
import ConfirmDelete from 'pages/CompaniesList/ConfirmDelete';
import { ModelUserHorairesListe } from 'models/ModelTypeData';
import { modelUserHorairesListe } from 'models/DefaultTypeData';

const UserSchedule = () => {
    const { dispatch } = useContext(NotificationContext);
    const [listeHoraires, setListeHoraires] = useState<ModelUserHorairesListe[]>([]);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [userSchedule, setUserSchedule] = useState<ModelUserHorairesListe>(modelUserHorairesListe);
    const [scheduleId, setScheduleId] = useState<number>(0);
    const [comfirmDelete, setConfirmDelete] = useState<boolean>(false);

    const GetUsersSchedule = async () => {
        const response = await GET(API.USER_SCHEDULE_LIST);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        setListeHoraires(response.data);
    };

    useEffect(() => {
        if (!openForm) GetUsersSchedule();
    }, [openForm]);

    const DeleteSchedule = async (id: string) => {
        const { status, message } = await DELETE(`${API.USER_SCHEDULE}/${id}`);
        if (!status) return dispatch({ status, message });
        dispatch({ status, message });
        GetUsersSchedule();
        setConfirmDelete(false);
    };

    const columnsTable: ColumnsType<ModelUserHorairesListe> = [
        {
            title: 'Tiers source',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.fk_livreur}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.concatNomPrenom}</p>
                    </Link>
                    <button
                        type="button"
                        className="outlined-none text-red-600  px-1"
                        onClick={() => {
                            setConfirmDelete(true);
                            setScheduleId(props.fk_livreur);
                        }}
                    >
                        <FaTrash />
                    </button>
                    <button
                        type="button"
                        className="outlined-none text-purple-600 px-1 text-lg"
                        onClick={() => {
                            setOpenForm(true);
                            setUserSchedule(props);
                        }}
                    >
                        <FaRegEdit />
                    </button>
                </span>
            ),
        },
        {
            title: 'Lundi',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => <span className="flex justify-between">{props.lundi}</span>,
        },
        {
            title: 'Mardi',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => <span className="flex justify-between">{props.mardi}</span>,
        },
        {
            title: 'Mercredi',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => <span className="flex justify-between">{props.mercredi}</span>,
        },
        {
            title: 'Jeudi',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => <span className="flex justify-between">{props.jeudi}</span>,
        },
        {
            title: 'Vendredi',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => <span className="flex justify-between">{props.vendredi}</span>,
        },
        {
            title: 'Samdi',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => <span className="flex justify-between">{props.samedi}</span>,
        },
    ];

    return (
        <section className="container mx-auto my-5">
            <Button onClick={() => setOpenForm(true)}>Créer horaire</Button>
            <Table
                columns={columnsTable}
                dataSource={listeHoraires}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: listeHoraires?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
            <SchedulForm open={openForm} setOpen={setOpenForm} schedule={userSchedule} setSchedule={setUserSchedule} />
            <ConfirmDelete open={comfirmDelete} setOpen={setConfirmDelete} id={`${scheduleId}`} Delete={DeleteSchedule} label="schedule" />
        </section>
    );
};

export default UserSchedule;
